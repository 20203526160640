import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Stack, Button } from '@mui/material';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function MenuDesktop() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-expressions

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Stack direction="row" spacing={3}>
      <Button
        color="info"
        variant="contained"
        rel="noopener"
        href="tel:+917410936009"
        startIcon={<Iconify icon="fluent:call-32-filled" width={20} height={20} />}
      >
        +917410936009
      </Button>
    </Stack>
  );
}
