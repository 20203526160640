import { Navigate, useRoutes } from 'react-router-dom';
//
import {
  About,
  BlogPosts,
  Contact,
  DemoQuery,
  DesktopPage,
  Faqs,
  HomePage,
  Page404,
  Privacy,
  ResellerPage,
  Terms,
  WAOLead,
  WaCRM,
  WaChatBot,
  WaMarketing,
} from './elements';
import CompactLayout from '../layouts/compact';
import MainLayout from '../layouts/main';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'whatsapp-chat-bot',
          element: <WaChatBot />,
        },
        {
          path: 'whatsapp-crm',
          element: <WaCRM />,
        },
        {
          path: 'reseller',
          element: <ResellerPage />,
        },
        { path: 'whatsapp-marketing', element: <WaMarketing /> },
        {
          path: 'wao-lead-scraper',
          element: <WAOLead />,
        },
        {
          element: <HomePage />,
          index: true,
        },
        {
          path: 'about-us',
          element: <About />,
        },
        {
          path: 'contact-us',
          element: <Contact />,
        },
        {
          path: 'demo-query',
          element: <DemoQuery />,
        },
        { path: 'privacy-policy', element: <Privacy /> },
        { path: 'terms-&-condition', element: <Terms /> },
        {
          path: 'desktop-tool',
          element: <DesktopPage />,
        },

        {
          path: 'faqs',
          element: <Faqs />,
        },
        {
          path: 'demo',
          children: [
            { element: <Navigate to="/demo/videos" replace />, index: true },
            { path: 'videos', element: <BlogPosts /> },
          ],
        },
      ],
    },

    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
