import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// MAIN
export const HomePage = Loadable(lazy(() => import('../pages/Home')));
export const About = Loadable(lazy(() => import('../pages/About')));
export const Contact = Loadable(lazy(() => import('../pages/Contact')));
export const DemoQuery = Loadable(lazy(() => import('../pages/DemoQuary')));
export const Privacy = Loadable(lazy(() => import('../pages/privacy')));
export const Terms = Loadable(lazy(() => import('../pages/terms')));
export const BlogPosts = Loadable(lazy(() => import('../pages/BlogPosts')));
export const DesktopPage = Loadable(lazy(() => import('../pages/DesktopHome')));
export const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
export const WaMarketing = Loadable(lazy(() => import('../pages/marketingCards/WaMarketing')));
export const WaChatBot = Loadable(lazy(() => import('../pages/marketingCards/WaChatBot')));
export const WaCRM = Loadable(lazy(() => import('../pages/marketingCards/WaCRM')));
export const WAOLead = Loadable(lazy(() => import('../pages/marketingCards/WAOLead')));
export const ResellerPage = Loadable(lazy(() => import('../pages/ResellerPage')));
