// _mock_

// components
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const menuConfig = [
  {
    title: 'Solutions',
    path: '#',
    // icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,

    children: [
      {
        items: [
          { title: 'WhatsApp Martketing', path: PATH_PAGE.wamarketing },
          { title: 'WhatsApp CRM', path: PATH_PAGE.wacrm },
          { title: 'WhatsEra Chat-Widget', path: PATH_PAGE.wachatbot },
          { title: 'WAO Lead Scraper', path: PATH_PAGE.waolead },
        ],
      },
    ],
  },
  { title: 'Become a Partner', path: PATH_PAGE.resellerPage, icon: 'mdi:partnership' },
];

export default menuConfig;
