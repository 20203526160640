// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOT_BLOG = '/demo';

// ----------------------------------------------------------------------

export const PATH_AUTH = 'https://app.whatsera.com';

export const PATH_PAGE = {
  resellerPage: 'reseller',
  wachatbot: '/whatsapp-chat-bot',
  wacrm: '/whatsapp-crm',
  wamarketing: '/whatsapp-marketing',
  waolead: 'wao-lead-scraper',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  privacy: '/privacy-policy',
  terms: '/terms-&-condition',
  pricing: '/pricing',
  about: '/about-us',
  contact: '/contact-us',
  demoquery: '/demo-query',
  desktop: '/desktop-tool',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  root: ROOT_BLOG,
  blog: {
    root: path(ROOT_BLOG, '/demo'),
    posts: path(ROOT_BLOG, '/demo/videos'),
  },
};
