import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box sx={{ width: 50, height: 31, ...sx }}>
      {' '}
      <svg width="100%" height="100%" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fillRule="evenodd"
            fill="url(#BG1)"
            clipRule="evenodd"
            d="M8.58403 20.5251C12.2443 17.8467 12.4111 17.7244 12.4188 17.7191C12.4198 17.7181 12.4719 17.6801 16.1207 15.0098C13.966 9.32988 12.5923 5.9684 11.9993 4.9254C11.1102 3.36089 10.1402 2.17632 8.94726 1.49283C6.43934 -0.176062 3.32164 -0.254093 0.914062 1.80925L8.58403 20.5251Z"
          />
          <path
            fillRule="evenodd"
            fill="url(#BG2)"
            clipRule="evenodd"
            d="M36.4769 18.3463C32.6529 27.8195 28.7 36.7447 24.1593 23.6523C23.5381 21.9692 23.0854 18.6982 22.0711 18.6982V18.7148C21.0569 18.7148 20.6042 21.9858 19.9831 23.6689C15.4422 36.7613 11.4893 27.8362 7.66529 18.3629C7.37751 17.6485 7.10107 16.9667 6.8645 16.3741C15.628 24.2291 14.8901 0.49881 22.0711 0.310248V0.293629C29.2522 0.48219 28.5143 24.2124 37.2777 16.3577C37.0413 16.9501 36.7649 17.6319 36.4769 18.3463"
          />
          <path
            fillRule="evenodd"
            fill="url(#BG3)"
            clipRule="evenodd"
            d="M36.9472 0.293614C39.138 0.293614 40.9141 2.80845 40.9141 5.91043C40.9141 9.01242 39.138 11.5273 36.9472 11.5273C34.7564 11.5273 32.9802 9.01242 32.9802 5.91043C32.9802 2.80845 34.7564 0.293614 36.9472 0.293614Z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
