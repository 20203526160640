/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/logo';
import SocialsButton from '../../components/SocialsButton';
import Image from '../../components/image';

// ----------------------------------------------------------------------

const head = 'WhatsEra';
const LINKS = [
  {
    headline: head,
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'FAQs', href: PATH_PAGE.faqs },
      { name: 'Become a Partner', href: PATH_PAGE.resellerPage },
    ],
  },
  {
    headline: 'Services',
    children: [
      { name: 'WhatsApp Marketing', href: PATH_PAGE.wamarketing },
      { name: 'WhatsApp CRM', href: PATH_PAGE.wacrm },
      { name: `${head} Chat Widget`, href: PATH_PAGE.wachatbot },
      { name: 'WAO Leads Scraper', href: PATH_PAGE.waolead },
    ],
  },
  {
    headline: 'Quick Links',
    children: [
      { name: 'Login', href: PATH_AUTH.login },
      { name: 'Register', href: PATH_AUTH.register },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_PAGE.terms },
      { name: 'Privacy Policy', href: PATH_PAGE.privacy },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'Support', href: 'tel:+917410936009' },
      { name: 'Email', href: 'mailto:sale@whatsera.com' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 7 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 5 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 2 } }}>
              {head} is a Digital Marketing Platform Where We Are Providing Marketing Consultancy to
              Grow Your Business We Have Simple and Ready to Your Software for Your Organization
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ my: 2, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>

            <Link target="_blank" href="https://www.softwaresuggest.com/whatsera">
              <Image
                src="https://d1myhw8pp24x4f.cloudfront.net/badge/find_us_on.png"
                sx={{ width: 174 }}
              />
            </Link>
          </Grid>

          <Grid item xs={12} md={8}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) =>
                    link.name === 'Login' || link.name === 'Register' ? (
                      <a
                        key={link.name}
                        style={{ display: 'block', color: 'inherit', textDecoration: 'none' }}
                        href="https://app.whatsera.com/auth/register"
                      >
                        {link.name}
                      </a>
                    ) : link.name === 'Email' || link.name === 'Support' ? (
                      <a
                        key={link.name}
                        style={{ display: 'block', color: 'inherit', textDecoration: 'none' }}
                        href={link.href}
                      >
                        {link.name}
                      </a>
                    ) : (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    )
                  )}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'center' },
          }}
        >
          © {new Date().getFullYear()}. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
