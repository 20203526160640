/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Masonry } from '@mui/lab';
import { Link, Paper, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../iconify';

// ----------------------------------------------------------------------

const ITEM_SPACING = 4;
const PARENT_ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

MegaMenuDesktopHorizon.propTypes = {
  navConfig: PropTypes.array,
};

export default function MegaMenuDesktopHorizon({ navConfig, ...other }) {
  return (
    <Stack direction="row" spacing={ITEM_SPACING} {...other}>
      {navConfig.map((parent) => (
        <MegaMenuItem key={parent.title} parent={parent} />
      ))}
    </Stack>
  );
}

// ----------------------------------------------------------------------

MegaMenuItem.propTypes = {
  parent: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string,
    more: PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    }),
    tags: PropTypes.array,
    products: PropTypes.array,
    children: PropTypes.array,
  }),
};

function MegaMenuItem({ parent }) {
  const { title, path, children, icon } = parent;
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (children) {
    return (
      <container>
        <ParentItem
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          path={path}
          title={title}
          icon={icon}
          open={open}
          hasSub
        />

        {open && (
          <Paper
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            sx={{
              p: 3,
              width: '15%',
              position: 'absolute',
              borderRadius: 2,
              top: PARENT_ITEM_HEIGHT,
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              zIndex: (theme) => theme.zIndex.modal,
              boxShadow: (theme) => theme.customShadows.z20,
            }}
          >
            <Masonry columns={1} spacing={2}>
              {children.map((list) => (
                <Stack key={list.subheader} spacing={1.25}>
                  {list.items.map((link) => (
                    <Link
                      noWrap
                      key={link.title}
                      component={RouterLink}
                      to={link.path}
                      underline="none"
                      sx={{
                        typography: 'body2',
                        color: 'text.primary',
                        fontSize: 13,
                        transition: (theme) => theme.transitions.create('all'),
                        '&:hover': { color: 'primary.main' },
                      }}
                    >
                      <Iconify
                        icon="carbon:dot-mark"
                        sx={{ mr: 1, color: theme.palette.warning.main }}
                      />
                      {link.title}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Masonry>
          </Paper>
        )}
      </container>
    );
  }

  return <ParentItem path={path} title={title} icon={icon} />;
}

// ----------------------------------------------------------------------

ParentItem.propTypes = {
  hasSub: PropTypes.bool,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
};

function ParentItem({ title, path, open, icon, hasSub, ...other }) {
  const activeStyle = {
    color: 'primary.main',
  };

  return (
    <Link
      to={path}
      component={RouterLink}
      underline="none"
      color="inherit"
      variant="subtitle2"
      sx={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        // textTransform: 'capitalize',
        height: PARENT_ITEM_HEIGHT,
        lineHeight: `${PARENT_ITEM_HEIGHT}px`,
        transition: (theme) => theme.transitions.create('all'),
        '&:hover': activeStyle,
        ...(open && activeStyle),
      }}
      {...other}
    >
      {icon && <Iconify sx={{ mr: 1, width: 20, height: 20 }} icon={icon} />}

      {title}
      {hasSub && <Iconify icon="eva:chevron-down-fill" sx={{ ml: 1, width: 20, height: 20 }} />}
    </Link>
  );
}
